<template>
  <v-form>
    <v-row>
      <v-card-text>
        <v-container>
          <template>
            <!-- Checkout Data -->
            <v-card class="custom-card">
              <v-skeleton-loader
                type="paragraph"
                v-if="isLoading"></v-skeleton-loader>
              <div v-else>
                <h2 class="text-center mt-4 mb-10">Checkout Data</h2>
                <template>
                  <div>
                    <v-row>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="(checkoutDataEntry, index) in users.products"
                          :key="index">
                          <v-row>
                            <v-col cols="12" sm="11" md="11">
                              <v-expansion-panel-header
                                >Product {{ index + 1 }}
                              </v-expansion-panel-header>
                            </v-col>
                          </v-row>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                  label="Kode Produk"
                                  hint="Product Code"
                                  outlined
                                  required
                                  disabled
                                  item-value="exid"
                                  item-text="productCode"
                                  :items="
                                    productCodes.map(
                                      (product) => product.productCode
                                    )
                                  "
                                  v-model="
                                    checkoutDataEntry.code
                                  "></v-autocomplete>
                                <v-text-field
                                  label="Nama Produk"
                                  hint="Product Name"
                                  outlined
                                  required
                                  disabled
                                  v-model="
                                    checkoutDataEntry.name
                                  "></v-text-field>
                                <v-text-field
                                  label="Jumlah"
                                  hint="Product Quantity"
                                  outlined
                                  required
                                  disabled
                                  type="number"
                                  v-model="
                                    checkoutDataEntry.quantity
                                  "></v-text-field>
                                <v-text-field
                                  label="Berat /Produk"
                                  hint="Weight /product"
                                  outlined
                                  required
                                  disabled
                                  v-model="
                                    checkoutDataEntry.unitWeight
                                  "></v-text-field>
                                <v-text-field
                                  label="Berat Total Produk"
                                  hint="Weight Total"
                                  outlined
                                  required
                                  disabled
                                  v-model="
                                    checkoutDataEntry.weightTotal
                                  "></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  label="Harga /Produk"
                                  hint="Harga /Produk"
                                  outlined
                                  required
                                  disabled
                                  type="number"
                                  v-model="
                                    checkoutDataEntry.unitPrice
                                  "></v-text-field>
                                <v-text-field
                                  label="Jumlah Harga"
                                  hint="Jumlah harga"
                                  outlined
                                  required
                                  disabled
                                  v-model="
                                    checkoutDataEntry.sumPrice
                                  "></v-text-field>
                                <v-text-field
                                  label="Diskon"
                                  hint="Diskon"
                                  outlined
                                  required
                                  disabled
                                  type="number"
                                  v-model="
                                    checkoutDataEntry.discount
                                  "></v-text-field>
                                <v-text-field
                                  label="Total Harga"
                                  hint="Total Harga"
                                  outlined
                                  disabled
                                  required
                                  v-model="
                                    checkoutDataEntry.priceTotal
                                  "></v-text-field>
                                <v-text-field
                                  label="Bonus"
                                  hint="Bonus"
                                  outlined
                                  required
                                  disabled
                                  v-model="
                                    checkoutDataEntry.bonus
                                  "></v-text-field>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                  </div>
                </template>
              </div>
            </v-card>
          </template>
        </v-container>
      </v-card-text>

      <!-- Delivery Data -->
      <v-card-text>
        <v-container>
          <template>
            <v-card class="custom-card">
              <v-skeleton-loader
                type="paragraph"
                v-if="isLoading"></v-skeleton-loader>
              <div v-else>
                <h2 class="text-center mt-4 mb-10">Delivery Data</h2>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      clearable
                      label="Ekspedisi"
                      v-model="users.delivery.expedition"
                      outlined
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      clearable
                      label="Gudang"
                      v-model="users.delivery.warehouse"
                      outlined
                      disabled></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      clearable
                      label="Provinsi"
                      v-model="users.address.province"
                      outlined
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      clearable
                      label="Kabupaten/Kota"
                      v-model="users.address.regency"
                      disabled
                      outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      clearable
                      label="Kecamatan"
                      v-model="users.address.district"
                      disabled
                      outlined></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6"
                    ><v-text-field
                      label="Biaya Penanganan"
                      v-model="users.delivery.handlingFee"
                      outlined
                      required
                      disabled></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="6" md="6"
                    ><v-text-field
                      label="Diskon Ongkir"
                      v-model="users.delivery.discount"
                      outlined
                      required
                      type="number"
                      disabled
                      @input="calculateTotalDelivery"></v-text-field
                  ></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" md="4"
                    ><v-text-field
                      label="Ongkos Kirim"
                      v-model="users.delivery.baseDeliveryFee"
                      outlined
                      required
                      disabled
                      @input="calculateTotalDelivery"></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="4" md="4"
                    ><v-text-field
                      label="Berat Total"
                      outlined
                      required
                      v-model="totalWeight"
                      disabled
                      readonly></v-text-field
                  ></v-col>
                  <v-col cols="12" sm="4" md="4"
                    ><v-text-field
                      label="Jumlah"
                      outlined
                      required
                      v-model="users.delivery.total"
                      disabled
                      readonly></v-text-field
                  ></v-col>
                </v-row>
              </div>
            </v-card>
          </template>
        </v-container>
      </v-card-text>

      <!-- Total Data -->
      <v-card-text>
        <v-container>
          <template>
            <v-card class="custom-card">
              <v-skeleton-loader
                type="paragraph"
                v-if="isLoading"></v-skeleton-loader>
              <div v-else>
                <h2 class="text-center mt-4 mb-10">Total Data</h2>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Total Biaya Produk"
                      v-model="users.sumProductPrice"
                      outlined
                      required
                      disabled></v-text-field>
                    <v-text-field
                      label="Total Biaya Pengiriman"
                      v-model="users.deliveryCostTotal"
                      outlined
                      required
                      disabled></v-text-field>
                    <v-text-field
                      label="Total Pembayaran"
                      v-model="users.paymentTotal"
                      outlined
                      required
                      disabled></v-text-field>
                    <v-text-field
                      label="Catatan Transfer"
                      v-model="users.tfNote"
                      outlined
                      required
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Total Diskon Produk"
                      v-model="users.sumProductDiscount"
                      outlined
                      required
                      disabled></v-text-field>
                    <v-text-field
                      label="Metode Pembayaran"
                      v-model="users.paymentMethod"
                      outlined
                      required
                      disabled></v-text-field>
                    <v-text-field
                      label="Nama Bank"
                      v-model="users.bankName"
                      outlined
                      required
                      disabled></v-text-field>
                    <v-col cols="12" style="padding: 0">
                      <v-row no-gutters class="justify-center">
                        <v-col justify="center" align="center">
                          <p class="mb-1 font-weight-medium">
                            Finance Validation
                          </p>
                          <v-chip
                            v-if="users.financeValidation == 'valid'"
                            color="success"
                            variant="flat">
                            Valid
                          </v-chip>
                          <v-chip
                            v-if="users.financeValidation == ''"
                            color="secondary"
                            variant="flat">
                            Payment Method COD
                          </v-chip>
                        </v-col>
                        <v-col justify="center" align="center">
                          <p class="mb-1 font-weight-medium">Status</p>
                          <v-chip
                            v-if="users.status == 'processed'"
                            color="success"
                            variant="flat">
                            Processed
                          </v-chip>
                          <v-chip v-else color="warning" variant="flat">
                            Pending
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Catatan Kurir"
                      v-model="users.delivery.notes"
                      outlined
                      required></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </template>
        </v-container>
      </v-card-text>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import Swal from "sweetalert2";

export default {
  name: "PuchaseData",

  props: {
    users: {
      type: Object,
      required: true,
    },
  },

  components: {
    VueSweetalert2,
  },

  props: {
    users: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      text: "",
      productCodes: [
        {
          productCode: "nut",
          exid: "nut",
          productName: "nutriflakes",
        },
        {
          productCode: "zym",
          exid: "zym",
          productName: "zymuno",
        },
        {
          productCode: "bio",
          exid: "bio",
          productName: "bio insuleaf",
        },
        {
          productCode: "lin",
          exid: "lin",
          productName: "etawalin",
        },
        {
          productCode: "eta",
          exid: "eta",
          productName: "etawaku",
        },
        {
          productCode: "fre",
          exid: "fre",
          productName: "freshmag",
        },
      ],
    };
  },

  mounted() {
    this.hideSkeleton();
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios
        .get(
          "https://order.genah.net/api/v2/orders/" + this.$route.params.id
          // "http://localhost:8080/orders/" + this.$route.params.id
        )
        .then((response) => {
          this.users = response.data.data;
          this.isLoading = false;

          this.isExported = this.users.isExported;

          this.$emit("users-loaded", this.users);
          this.calculateTotalWeight();
        })
        .catch((error) => console.log(error));
    },

    hideSkeleton() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },

    calculateTotalWeight() {
      let total = 0;
      this.users.products.forEach(product => {
        total += parseFloat(product.weightTotal) || 0;
      });
      this.totalWeight = parseFloat(total.toFixed(2)); // Membulatkan ke 2 angka di belakang koma
    },
  },
  watch: {
    // Watcher untuk mengamati perubahan pada users.products
    "users.products": {
      handler: function () {
        this.calculateTotalWeight();
      },
      deep: true,
    },
  },
};
</script>
