<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  created() {
    const token = localStorage.getItem("token");
    const apiKey = localStorage.getItem("X-API-Key");
    if ((token, apiKey)) {
      axios.defaults.headers.common["Authorization"] = token;
      axios.defaults.headers.common["X-API-Key"] = apiKey;
    }
  },
};
</script>