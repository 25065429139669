import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/HomeView.vue";
import FormView from "../views/FormView.vue";
import LayoutMaster from "../LayoutMaster.vue";
import Login from "../views/LoginView.vue";

Vue.use(VueRouter);

// Middleware untuk memeriksa login
const requireAuth = (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");
  if (isAuthenticated) {
    // Jika user sudah login, navigasikan ke dashboard
    if (to.path === "/login") {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/",
    name: "login",
    redirect: "/login",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: LayoutMaster,
    beforeEnter: requireAuth,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/orders/:id",
        name: "FormView",
        component: FormView,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // {
  //   path: "/form-dialog/:userId",
  //   name: "form-dialog",
  //   component: FormDialog,
  //   props: true // Pass route params as props
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
