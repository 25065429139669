<template>
  <v-card class="card-custom">
    <v-card-title class="card-title-custom">
      <ExportData />
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        class="custom-text-field"
        single-line
        hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="users"
      :search="search"
      item-key="id"
      class="elevation-1"
      v-if="!loading">
      <template v-slot:item.no="{ item, index }">
        {{ startIndex + index + 1 }}
      </template>
      <!-- <template v-slot:item.isValidOrder="{ item }">
        <v-chip v-if="item.isValidOrder" color="success"> Benar </v-chip>
        <v-chip v-else color="error"> Salah </v-chip>
      </template> -->
      <template v-slot:item.paymentMethod="{ item }">
        <v-chip v-if="item.paymentMethod == 'tf'" color="primary">Transfer</v-chip>
        <v-chip v-else color="#FF6F00" text-color="white">COD</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link v-if="item.isExported" :to="`/orders/${item.id}`">
          <v-btn small icon @click="confirmDeleteData(item)">
            <v-icon size="x-large" color="error">mdi-delete-circle</v-icon>
          </v-btn>
        </router-link>
        <FormDialog :key="item.id" :users="item" v-if="item.isExported" />
        <template v-else>
          <router-link :to="`/orders/${item.id}`">
            <v-btn small icon>
              <v-icon size="x-large" color="#795548">mdi-pencil-circle</v-icon>
            </v-btn>
          </router-link>
          <v-btn small icon @click="confirmDeleteData(item)">
            <v-icon size="x-large" color="error">mdi-delete-circle</v-icon>
          </v-btn>
        </template>
      </template>
    </v-data-table>
    <v-progress-linear v-else indeterminate color="success"></v-progress-linear>
    <template>
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="7"
        navigation-color="#1B5E20"
        navigation-text-color="#FFFF"
        color="#1B5E20"
        @input="handlePageChange"></v-pagination>
      <p class="text-center green white--text text--darken-2">
        Halaman : {{ page }}
      </p>
    </template>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import VueSweetalert2 from "vue-sweetalert2";
import ExportData from "./ExportData.vue";
import _debounce from "lodash/debounce";

export default {
  name: "HelloWorld",

  components: {
    VueSweetalert2,
    ExportData,
  },
  data() {
    return {
      search: "",
      startDate: null,
      endDate: null,
      selectedExpedition: null,
      selectedWarehouse: null,
      totalItems: 0,
      loading: false,
      currentPage: 0,
      totalPages: 0,
      page: 1,
      itemsPerPage: 10,
      startIndex: 0,
      debouncedSearch: "",
      selected: [],
      filteredItems: [],
      expeditions: [
        {
          expedition: "Jnt",
          exid: "jnt",
        },
        {
          expedition: "Ninja",
          exid: "ninja",
        },
        {
          expedition: "SiCepat",
          exid: "sicepat",
        },
      ],
      warehouse: [
        {
          warehouse: "Kosambi",
          exid: "kosambi",
        },
        {
          warehouse: "Tandes",
          exid: "tandes",
        },
        {
          warehouse: "Cilacap",
          exid: "cilacap",
        },
      ],
      headers: [
        {
          align: "center",
          text: "No",
          value: "no",
        },
        {
          text: "Nama Customer",
          value: "customer.name",
        },
        {
          text: "Nomor HP",
          value: "customer.whatsapp",
        },
        // { text: "Diskon Ongkir", value: "deliveryData.deliveryDiscount" },
        { text: "Tanggal", value: "createdAtLocal" },
        {
          text: "Ekspedisi",
          value: "delivery.expedition",
        },
        { text: "Gudang", value: "delivery.warehouse" },
        { text: "Metode", value: "paymentMethod" },
        { text: "Action", value: "actions" },
      ],
      users: [],
      invalidDates: false,
    };
  },
  computed: {
    serverItems() {
      const startIndex = this.currentPage * this.itemsPerPage + 1;
      const endIndex = startIndex + this.itemsPerPage;

      return this.filteredItems.slice(startIndex, endIndex + 1);
    },
  },

  methods: {
    getDataWithOptions(page = 0) {
      const actualPage = this.currentPage > 0 ? this.currentPage - 1 : 0;
      // let apiUrl = `http://localhost:8080/orders?p=${actualPage}`;
      let apiUrl = `https://order.genah.net/api/v2/orders?p=${actualPage}`;
      let apiUrlSearch = `https://order.genah.net/api/v2/orders?`;
      // let apiUrlSearch = `http://localhost:8080/orders?`;

      if (this.startDate && this.endDate) {
        const formattedStartDate = moment(this.startDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
        const formattedEndDate = moment(this.endDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
        apiUrl += `&timeStart=${formattedStartDate}&timeEnd=${formattedEndDate}`;
      }

      if (this.debouncedSearch) {
        apiUrlSearch += `s=${encodeURIComponent(this.debouncedSearch)}&p=${actualPage}`;
      }

      const apiUrlToUse = this.debouncedSearch ? apiUrlSearch : apiUrl;

      this.loading = true;

      axios
        .get(apiUrlToUse)
        .then((res) => {
          if (
            !res.data ||
            !res.data.data ||
            res.data.data.orders.length === 0
          ) {
            if (page > 0) {
              this.$swal({
                title: "Data Tidak Ditemukan",
                text: "Tidak ada data yang ditemukan pada halaman ini.",
                icon: "info",
              });
            }
            this.loading = false;
            return;
          }

          const totalCount = res.data.totalCount;
          const data = res.data.data.orders;

          this.users = data.map((order) => {
            const createdAtLocal = moment
              .utc(order.createdAt)
              .utcOffset("+0700")
              .format("DD MMMM YYYY, HH:mm");

            order.createdAtLocal = createdAtLocal;

            return order;
          });

          this.totalItems = totalCount;
          this.totalPages = Math.ceil(totalCount / this.itemsPerPage);
          this.currentPage = page;
          this.loading = false;
        })
        .catch((error) => {
          // console.error("Error mengambil data:", error);
          this.$swal({
            title: "Error saat mengambil data",
            text: "Terjadi kesalahan saat mengambil data dari server",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          this.loading = false;
        });
    },

    handlePageChange(page) {
      this.currentPage = page;
      this.getDataWithOptions(this.currentPage);
    },

    confirmDeleteData(user) {
      Swal.fire({
        title: "Yakin Hapus Data?",
        text: "Data yang dihapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(user);
        }
      });
    },

    deleteData(user) {
      axios
        .delete(`https://order.genah.net/api/v2/orders/${user.id}`)
        // .delete(`http://localhost:8080/orders/${user.id}`)
        .then((response) => {
          this.$swal("Data Berhasil Dihapus");
          console.log(response);
          this.$router.go(0);
        })
        .catch((error) => {
          this.$swal({
            title: "Data Gagal Dihapus",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          this.validation = error.response.data.data;
        });
    },
  },
  watch: {
    search: _debounce(function (newVal) {
      this.debouncedSearch = newVal;
      if (!newVal) {
        this.getDataWithOptions(0);
      } else {
        this.getDataWithOptions();
      }
    }, 800),
    startDate: function (newStartDate, oldStartDate) {
      if (newStartDate !== oldStartDate) {
        if (!newStartDate) {
          this.getDataWithOptions(0);
        } else {
          this.getDataWithOptions();
        }
      }
    },
    endDate: function (newEndDate, oldEndDate) {
      if (newEndDate !== oldEndDate) {
        if (!newEndDate) {
          this.getDataWithOptions(0);
        } else {
          this.getDataWithOptions();
        }
      }
    },
  },

  mounted() {
    this.getDataWithOptions(0);
    this.getDataSearchByName();
  },
};
</script>

<style scoped>
.card-custom {
  margin: 80px;
}

.custom-text-field {
  width: 100px;
  margin-bottom: 10px;
}

.date-input {
  width: 150px;
  margin-top: 10px;
  margin-left: 10px;
}
</style>
