<template>
  <body>
    <div class="wrapper fadeInDown">
      <div id="formContent">
        <!-- Tabs Titles -->
        <h2 class="active">Sign In</h2>

        <!-- Icon -->
        <div class="fadeIn first mt-6 mb-5">
          <img src="../assets/BAGONK-black.png" />
        </div>

        <!-- Login Form -->
        <form @submit.prevent="login">
          <input
            type="text"
            id="login"
            class="fadeIn second input-customize"
            name="login"
            placeholder="Nickname"
            v-model="nickname" />
          <input
            type="password"
            id="password"
            class="fadeIn third input-customize"
            name="login"
            placeholder="Password"
            v-model="password" />
          <input
            type="submit"
            class="fadeIn fourth input-custom mt-6 mb-6"
            :value="loading ? 'Logging in...' : 'Log In'"
            :disabled="loading" />
          <p v-if="error" class="text-danger">{{ error }}</p>
        </form>

        <Spinner v-if="loading" />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </body>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../components/Spinner.vue";
import "../css/login.css";

export default {
  components: Spinner,
  data() {
    return {
      nickName: "",
      password: "",
      error: "",
      loading: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;

      const apiKey = "jT9AqE2XKMRtk78DKfleQMVdM8XA62O+1Uk7OgPcmY4=";

      const config = {
        headers: {
          "X-API-Key": apiKey,
        },
      };

      try {
        const response = await axios
          .post(
            "https://order.genah.net/api/v2/login",
            // "http://localhost:8080/login",
            {
              nickname: this.nickname,
              password: this.password,
            },
            config
          )
          .catch((e) => {
            console.log("axios post exception", e);
          });

        const res = response.data;
        const token = res.data.token;

        axios.defaults.headers.common["Authorization"] = token;
        axios.defaults.headers.common["X-API-Key"] = apiKey;
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = true;

        localStorage.setItem("token", token);
        localStorage.setItem("X-API-Key", apiKey);

        // console.log(token);

        this.$router.push({ name: "dashboard" });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          Swal.fire({
            title: "Not Found",
            text: "The requested resource was not found.",
            icon: "error",
          });
        } else if (error.response && error.response.status === 500) {
          Swal.fire({
            title: "Internal Server Error",
            text: "Check Internet Connection!",
            icon: "error",
          });
        } else if (error.response && error.response.status === 502) {
          Swal.fire({
            title: "Bad Gateway",
            text: "The server is currently unavailable (Bad Gateway).",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Invalid!",
            text: "Invalid Account Data",
            icon: "error",
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
